import React from "react";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Termsandconditions = () => {
  const navigate = useNavigate();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div class="block w-full  py-6 px-6  text-white border-b border-[#F26649]">
        <div class="flex items-center justify-between text-gray-900">
          <a href="#">
            <img src={Logo} alt="" />
          </a>
          <ul class="hidden items-center lg:gap-14 gap-4 md:flex">
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span class="text-with-dash line">Description</span>
              </a>
            </li>
            <li class="block text-base font-normal text-black leading-normal">
              <a class="items-center lg:flex hidden" href="#">
                <iconify-icon
                  icon="pepicons-pencil:line-y"
                  style={{ color: " #a7a7a8;" }}
                  width="16"
                ></iconify-icon>
              </a>
            </li>
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer active">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                <span class="text-with-dash line">Terms and Conditions</span>
              </a>
            </li>
            <li class="block text-base font-normal text-black leading-normal">
              <a class="lg:flex items-center  hidden" href="#">
                <iconify-icon
                  icon="pepicons-pencil:line-y"
                  style={{ color: "#a7a7a8;" }}
                  width="16"
                ></iconify-icon>
              </a>
            </li>
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                <span class="text-with-dash line">Privacy Policy</span>
              </a>
            </li>
          </ul>
          <div class="md:hidden" id="menu-icon">
            <iconify-icon
              icon="ri:menu-3-line"
              style={{ color: "black;" }}
              width="32"
              height="32"
              class="mt-2"
              id="menu-icon-btn"
            ></iconify-icon>
          </div>
        </div>

        <div class="md:hidden overflow-hidden" id="offcanvas-menu">
          <div class="close-icon" id="close-icon-btn">
            <iconify-icon
              icon="ri:close-line"
              style={{ color: "white;" }}
              width="30"
              height="30"
            ></iconify-icon>
          </div>
          <ul class="items-center lg:gap-14 gap-10 md:flex space-y-12 mt-12">
            <li class="block text-base font-normal hover:text-blue leading-normal cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                Description
              </a>
            </li>
            <li class="block text-base font-normal hover:text-blue leading-normal cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                Terms and Conditions
              </a>
            </li>
            <li class="block text-base font-normal hover:text-blue leading-normal cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                Pricay Policy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="md:py-8 md:px-8">
        <div className="mb-4">
          <h1 className="text-2xl mb-2 font-medium">General Terms</h1>
          <p className="mb-3">
            By accessing and placing an order with Glover’s Scorebooks, you
            confirm that you are in agreement with and bound by the terms of
            service contained in the Terms & Conditions outlined below. These
            terms apply to the entire website and any email or other type of
            communication between you and Glover’s Scorebooks.
          </p>
          <p className="mb-3">
            Under no circumstances shall Glover’s Scorebooks team be liable for
            any direct, indirect, special, incidental or consequential damages,
            including, but not limited to, loss of data or profit, arising out
            of the use, or the inability to use, the materials on this site,
            even if Glover’s Scorebooks team or an authorized representative has
            been advised of the possibility of such damages. If your use of
            materials from this site results in the need for servicing, repair
            or correction of equipment or data, you assume any costs thereof.
          </p>
          <p className="mb-3">
            Glover’s Scorebooks will not be responsible for any outcome that may
            occur during the course of usage of our resources. We reserve the
            rights to change prices and revise the resources usage policy in any
            moment.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">License</h1>
          <p className="mb-3">
            Glover’s Scorebooks grants you a revocable, non-exclusive,
            non-transferable, limited license to download, install and use the
            app strictly in accordance with the terms of this Agreement.
          </p>
          <p className="mb-3">
            These Terms & Conditions are a contract between you and Glover’s
            Scorebooks (referred to in these Terms & Conditions as "Glover’s
            Scorebooks", "us", "we" or "our"), the provider of the Glover’s
            Scorebooks website and the services accessible from the Glover’s
            Scorebooks website (which are collectively referred to in these
            Terms & Conditions as the "Glover’s Scorebooks Service").
          </p>
          <p className="mb-3">
            You are agreeing to be bound by these Terms & Conditions. If you do
            not agree to these Terms & Conditions, please do not use the
            Glover’s Scorebooks Service. In these Terms & Conditions, "you"
            refers both to you as an individual and to the entity you represent.
            If you violate any of these Terms & Conditions, we reserve the right
            to cancel your account or block access to your account without
            notice.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">
            Definitions and key terms
          </h1>
          <p className="mb-3">
            To help explain things as clearly as possible in this Terms &
            Conditions, every time any of these terms are referenced, are
            strictly defined as:
          </p>
          <ul>
            <li>
              ● Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>
              ● Company: when this terms mention “Company,” “we,” “us,” or
              “our,” it refers to E.C. Frazier & Associates, Inc., (6600 Broad
              St. Waco, TX 76712), that is responsible for your information
              under this Terms & Conditions.
            </li>
            <li>
              ● Country: where Glover’s Scorebooks or the owners/founders of
              Glover’s Scorebooks are based, in this case is United States.
            </li>
            <li>
              ● Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit Glover’s
              Scorebooks and use the services
            </li>
            <li>
              ● Service: refers to the service provided by Glover’s Scorebooks
              as described in the relative terms (if available) and on this
              platform.
            </li>
            <li>
              ● Third-party service: refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide our
              content or whose products or services we think may interest you.
            </li>
            <li>
              ● App/Application: Glover’s Scorebooks app, refers to the SOFTWARE
              PRODUCT identified above.
            </li>
            <li>
              ● You: a person or entity that is registered with Glover’s
              Scorebooks to use the Services.
            </li>
          </ul>
          {/* <p className="pt-4">
            This Terms & Conditions were created with{" "}
            <a href="" className="underline text-[#005DAB]">
              Termify
            </a>
            .
          </p> */}
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Restrictions</h1>
          <p className="mb-3">
            You agree not to, and you will not permit others to:
          </p>
          <ul>
            <li>
              ● License, sell, rent, lease, assign, distribute, transmit, host,
              outsource, disclose or otherwise commercially exploit the app or
              make the platform available to any third party.
            </li>
            <li>
              Modify, make derivative works of, disassemble, decrypt, reverse
              compile or reverse engineer any part of the app.
            </li>
            <li>
              ● Remove, alter or obscure any proprietary notice (including any
              notice of copyright or trademark) of Glover’s Scorebooks or its
              affiliates, partners, suppliers or the licensors of the app.
            </li>
          </ul>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Payment</h1>
          <p className="mb-3">
            If you register to any of our recurring payment plans, you agree to
            pay all fees or charges to your account for the Service in
            accordance with the fees, charges and billing terms in effect at the
            time that each fee or charge is due and payable. Unless otherwise
            indicated in an order form, you must provide Glover’s Scorebooks
            with a valid credit card (Visa, MasterCard, or any other issuer
            accepted by us) (“Payment Provider”) as a condition to signing up
            for the Premium plan. Your Payment Provider agreement governs your
            use of the designated credit card account, and you must refer to
            that agreement and not these Terms to determine your rights and
            liabilities with respect to your Payment Provider. By providing
            Glover’s Scorebooks with your credit card number and associated
            payment information, you agree that Glover’s Scorebooks is
            authorized to verify information immediately, and subsequently
            invoice your account for all fees and charges due and payable to
            Glover’s Scorebooks hereunder and that no additional notice or
            consent is required. You agree to immediately notify Glover’s
            Scorebooks of any change in your billing address or the credit card
            used for payment hereunder. Glover’s Scorebooks reserves the right
            at any time to change its prices and billing methods, either
            immediately upon posting on our Site or by e-mail delivery to your
            organization’s administrator(s).
          </p>
          <p className="mb-3">
            Any attorney fees, court costs, or other costs incurred in
            collection of delinquent undisputed amounts shall be the
            responsibility of and paid for by you.
          </p>
          <p className="mb-3">
            No contract will exist between you and Glover’s Scorebooks for the
            Service until Glover’s Scorebooks accepts your order by a
            confirmatory e-mail, SMS/MMS message, or other appropriate means of
            communication.
          </p>
          <p className="mb-3">
            You are responsible for any third-party fees that you may incur when
            using the Service.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">
            Return and Refund Policy
          </h1>
          <p className="mb-3">
            Thanks for shopping at Glover’s Scorebooks. We appreciate the fact
            that you like to buy the stuff we build. We also want to make sure
            you have a rewarding experience while you’re exploring, evaluating,
            and purchasing our products.
          </p>
          <p className="mb-3">
            As with any shopping experience, there are terms and conditions that
            apply to transactions at Glover’s Scorebooks. We’ll be as brief as
            our attorneys will allow. The main thing to remember is that by
            placing an order or making a purchase at Glover’s Scorebooks, you
            agree to the terms along with Glover’s Scorebooks’s Privacy Policy.
          </p>
          <p className="mb-3">
            If, for any reason, You are not completely satisfied with any good
            or service that we provide, don't hesitate to contact us and we will
            discuss any of the issues you are going through with our product.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Your Suggestions</h1>
          <p className="mb-3">
            Any feedback, comments, ideas, improvements or suggestions
            (collectively, "Suggestions") provided by you to Glover’s Scorebooks
            with respect to the app shall remain the sole and exclusive property
            of Glover’s Scorebooks.
          </p>
          <p className="mb-3">
            Glover’s Scorebooks shall be free to use, copy, modify, publish, or
            redistribute the Suggestions for any purpose and in any way without
            any credit or any compensation to you.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Your Consent</h1>
          <p className="mb-3">
            We've updated our Terms & Conditions to provide you with complete
            transparency into what is being set when you visit our site and how
            it's being used. By using our app, registering an account, or making
            a purchase, you hereby consent to our Terms & Conditions.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Links to Other Websites</h1>
          <p className="mb-3">
            This Terms & Conditions applies only to the Services. The Services
            may contain links to other websites not operated or controlled by
            Glover’s Scorebooks. We are not responsible for the content,
            accuracy or opinions expressed in such websites, and such websites
            are not investigated, monitored or checked for accuracy or
            completeness by us. Please remember that when you use a link to go
            from the Services to another website, our Terms & Conditions are no
            longer in effect. Your browsing and interaction on any other
            website, including those that have a link on our platform, is
            subject to that website’s own rules and policies. Such third parties
            may use their own cookies or other methods to collect information
            about you.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Cookies</h1>
          <p className="mb-3">
            Glover’s Scorebooks uses "Cookies" to identify the areas of our app
            that you have visited. A Cookie is a small piece of data stored on
            your computer or mobile device by your web browser. We use Cookies
            to enhance the performance and functionality of our app but are
            non-essential to their use. However, without these cookies, certain
            functionality like videos may become unavailable or you would be
            required to enter your login details every time you visit the app as
            we would not be able to remember that you had logged in previously.
            Most web browsers can be set to disable the use of Cookies. However,
            if you disable Cookies, you may not be able to access functionality
            on our app correctly or at all. We never place Personally
            Identifiable Information in Cookies.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">
            Changes To Our Terms & Conditions
          </h1>
          <p className="mb-3">
            You acknowledge and agree that Glover’s Scorebooks may stop
            (permanently or temporarily) providing the Service (or any features
            within the Service) to you or to users generally at Glover’s
            Scorebooks’s sole discretion, without prior notice to you. You may
            stop using the Service at any time. You do not need to specifically
            inform Glover’s Scorebooks when you stop using the Service. You
            acknowledge and agree that if Glover’s Scorebooks disables access to
            your account, you may be prevented from accessing the Service, your
            account details or any files or other materials which is contained
            in your account.
          </p>
          <p className="mb-3">
            If we decide to change our Terms & Conditions, we will post those
            changes on this page, and/or update the Terms & Conditions
            modification date below
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">
            Modifications to Our app
          </h1>
          <p className="mb-3">
            Glover’s Scorebooks reserves the right to modify, suspend or
            discontinue, temporarily or permanently, the app or any service to
            which it connects, with or without notice and without liability to
            you.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Updates to Our app</h1>
          <p className="mb-3">
            Glover’s Scorebooks may from time to time provide enhancements or
            improvements to the features/ functionality of the app, which may
            include patches, bug fixes, updates, upgrades and other
            modifications ("Updates").
          </p>
          <p className="mb-3">
            Updates may modify or delete certain features and/or functionalities
            of the app. You agree that Glover’s Scorebooks has no obligation to
            (i) provide any Updates, or (ii) continue to provide or enable any
            particular features and/or functionalities of the app to you.
          </p>
          <p className="mb-3">
            You further agree that all Updates will be (i) deemed to constitute
            an integral part of the app, and (ii) subject to the terms and
            conditions of this Agreement.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Third-Party Services</h1>
          <p className="mb-3">
            We may display, include or make available third-party content
            (including data, information, applications and other products
            services) or provide links to third-party websites or services
            ("Third- Party Services").
          </p>
          <p className="mb-3">
            You acknowledge and agree that Glover’s Scorebooks shall not be
            responsible for any Third-Party Services, including their accuracy,
            completeness, timeliness, validity, copyright compliance, legality,
            decency, quality or any other aspect thereof. Glover’s Scorebooks
            does not assume and shall not have any liability or responsibility
            to you or any other person or entity for any Third-Party Services.
          </p>
          <p className="mb-3">
            Third-Party Services and links thereto are provided solely as a
            convenience to you and you access and use them entirely at your own
            risk and subject to such third parties' terms and conditions.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Term and Termination</h1>
          <p className="mb-3">
            This Agreement shall remain in effect until terminated by you or
            Glover’s Scorebooks.
          </p>
          <p className="mb-3">
            Glover’s Scorebooks may, in its sole discretion, at any time and for
            any or no reason, suspend or terminate this Agreement with or
            without prior notice.
          </p>
          <p className="mb-3">
            This Agreement will terminate immediately, without prior notice from
            Glover’s Scorebooks, in the event that you fail to comply with any
            provision of this Agreement. You may also terminate this Agreement
            by deleting the app and all copies thereof from your computer.
          </p>
          <p className="mb-3">
            Upon termination of this Agreement, you shall cease all use of the
            app and delete all copies of the app from your computer.
          </p>
          <p className="mb-3">
            Termination of this Agreement will not limit any of Glover’s
            Scorebooks's rights or remedies at law or in equity in case of
            breach by you (during the term of this Agreement) of any of your
            obligations under the present Agreement.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">
            Copyright Infringement Notice
          </h1>
          <p className="mb-3">
            If you are a copyright owner or such owner’s agent and believe any
            material on our app constitutes an infringement on your copyright,
            please contact us setting forth the following information: (a) a
            physical or electronic signature of the copyright owner or a person
            authorized to act on his behalf; (b) identification of the material
            that is claimed to be infringing; (c) your contact information,
            including your address, telephone number, and an email; (d) a
            statement by you that you have a good faith belief that use of the
            material is not authorized by the copyright owners; and (e) the a
            statement that the information in the notification is accurate, and,
            under penalty of perjury you are authorized to act on behalf of the
            owner.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Indemnification</h1>
          <p className="mb-3">
            You agree to indemnify and hold Glover’s Scorebooks and its parents,
            subsidiaries, affiliates, officers, employees, agents, partners and
            licensors (if any) harmless from any claim or demand, including
            reasonable attorneys' fees, due to or arising out of your: (a) use
            of the app; (b) violation of this Agreement or any law or
            regulation; or (c) violation of any right of a third party.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">No Warranties</h1>
          <p className="mb-3">
            The app is provided to you "AS IS" and "AS AVAILABLE" and with all
            faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, Glover’s Scorebooks, on its
            own behalf and on behalf of its affiliates and its and their
            respective licensors and service providers, expressly disclaims all
            warranties, whether express, implied, statutory or otherwise, with
            respect to the app, including all implied warranties of
            merchantability, fitness for a particular purpose, title and
            non-infringement, and warranties that may arise out of course of
            dealing, course of performance, usage or trade practice. Without
            limitation to the foregoing, Glover’s Scorebooks provides no
            warranty or undertaking, and makes no representation of any kind
            that the app will meet your requirements, achieve any intended
            results, be compatible or work with any other software, apps,
            systems or services, operate without interruption, meet any
            performance or reliability standards or be error free or that any
            errors or defects can or will be corrected.
          </p>
          <p className="mb-3">
            Without limiting the foregoing, neither Glover’s Scorebooks nor any
            Glover’s Scorebooks's provider makes any representation or warranty
            of any kind, express or implied: (i) as to the operation or
            availability of the app, or the information, content, and materials
            or products included thereon; (ii) that the app will be
            uninterrupted or error-free; (iii) as to the accuracy, reliability,
            or currency of any information or content provided through the app;
            or (iv) that the app, its servers, the content, or e-mails sent from
            or on behalf of Glover’s Scorebooks are free of viruses, scripts,
            trojan horses, worms, malware, timebombs or other harmful
            components.
          </p>
          <p className="mb-3">
            Some jurisdictions do not allow the exclusion of or limitations on
            implied warranties or the limitations on the applicable statutory
            rights of a consumer, so some or all of the above exclusions and
            limitations may not apply to you.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Limitation of Liability</h1>
          <p className="mb-3">
            Notwithstanding any damages that you might incur, the entire
            liability of Glover’s Scorebooks and any of its suppliers under any
            provision of this Agreement and your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by you for
            the app.
          </p>
          <p className="mb-3">
            To the maximum extent permitted by applicable law, in no event shall
            Glover’s Scorebooks or its suppliers be liable for any special,
            incidental, indirect, or consequential damages whatsoever
            (including, but not limited to, damages for loss of profits, for
            loss of data or other information, for business interruption, for
            personal injury, for loss of privacy arising out of or in any way
            related to the use of or inability to use the app, third-party
            software and/or third-party hardware used with the app, or otherwise
            in connection with any provision of this Agreement), even if
            Glover’s Scorebooks or any supplier has been advised of the
            possibility of such damages and even if the remedy fails of its
            essential purpose.
          </p>
          <p className="mb-3">
            Some states/jurisdictions do not allow the exclusion or limitation
            of incidental or consequential damages, so the above limitation or
            exclusion may not apply to you.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Severability</h1>
          <p className="mb-3">
            If any provision of this Agreement is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <p className="mb-3">
            This Agreement, together with the Privacy Policy and any other legal
            notices published by Glover’s Scorebooks on the Services, shall
            constitute the entire agreement between you and Glover’s Scorebooks
            concerning the Services. If any provision of this Agreement is
            deemed invalid by a court of competent jurisdiction, the invalidity
            of such provision shall not affect the validity of the remaining
            provisions of this Agreement, which shall remain in full force and
            effect. No waiver of any term of this Agreement shall be deemed a
            further or continuing waiver of such term or any other term, and
            Glover’s Scorebooks’s failure to assert any right or provision under
            this Agreement shall not constitute a waiver of such right or
            provision. YOU AND Glover’s Scorebooks AGREE THAT ANY CAUSE OF
            ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
            WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE,
            SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Waiver</h1>
          <p className="mb-3">
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Agreement shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute waiver of any subsequent breach.
          </p>
          <p className="mb-3">
            No failure to exercise, and no delay in exercising, on the part of
            either party, any right or any power under this Agreement shall
            operate as a waiver of that right or power. Nor shall any single or
            partial exercise of any right or power under this Agreement preclude
            further exercise of that or any other right granted herein. In the
            event of a conflict between this Agreement and any applicable
            purchase or other terms, the terms of this Agreement shall govern.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">
            Amendments to this Agreement
          </h1>
          <p className="mb-3">
            Glover’s Scorebooks reserves the right, at its sole discretion, to
            modify or replace this Agreement at any time. If a revision is
            material we will provide at least 30 days' notice prior to any new
            terms taking effect. What constitutes a material change will be
            determined at our sole discretion.
          </p>
          <p className="mb-3">
            By continuing to access or use our app after any revisions become
            effective, you agree to be bound by the revised terms. If you do not
            agree to the new terms, you are no longer authorized to use Glover’s
            Scorebooks.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Entire Agreement</h1>
          <p className="mb-3">
            The Agreement constitutes the entire agreement between you and
            Glover’s Scorebooks regarding your use of the app and supersedes all
            prior and contemporaneous written or oral agreements between you and
            Glover’s Scorebooks.
          </p>
          <p className="mb-3">
            You may be subject to additional terms and conditions that apply
            when you use or purchase other Glover’s Scorebooks's services, which
            Glover’s Scorebooks will provide to you at the time of such use or
            purchase.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Updates to Our Terms</h1>
          <p className="mb-3">
            We may change our Service and policies, and we may need to make
            changes to these Terms so that they accurately reflect our Service
            and policies. Unless otherwise required by law, we will notify you
            (for example, through our Service) before we make changes to these
            Terms and give you an opportunity to review them before they go into
            effect. Then, if you continue to use the Service, you will be bound
            by the updated Terms. If you do not want to agree to these or any
            updated Terms, you can delete your account.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Intellectual Property</h1>
          <p className="mb-3">
            The app and its entire contents, features and functionality
            (including but not limited to all information, software, text,
            displays, images, video and audio, and the design, selection and
            arrangement thereof), are owned by Glover’s Scorebooks, its
            licensors or other providers of such material and are protected by
            United States and international copyright, trademark, patent, trade
            secret and other intellectual property or proprietary rights laws.
            The material may not be copied, modified, reproduced, downloaded or
            distributed in any way, in whole or in part, without the express
            prior written permission of Glover’s Scorebooks, unless and except
            as is expressly provided in these Terms & Conditions. Any
            unauthorized use of the material is prohibited.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Agreement to Arbitrate</h1>
          <p className="mb-3">
            This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A
            DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
            REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Glover’s
            Scorebooks’s INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means
            any dispute, action, or other controversy between you and Glover’s
            Scorebooks concerning the Services or this agreement, whether in
            contract, warranty, tort, statute, regulation, ordinance, or any
            other legal or equitable basis. “Dispute” will be given the broadest
            possible meaning allowable under law.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Notice of Dispute</h1>
          <p className="mb-3">
            In the event of a dispute, you or Glover’s Scorebooks must give the
            other a Notice of Dispute, which is a written statement that sets
            forth the name, address, and contact information of the party giving
            it, the facts giving rise to the dispute, and the relief requested.
            You must send any Notice of Dispute via email to:
            tracysaul@fraziersports.com. Glover’s Scorebooks will send any
            Notice of Dispute to you by mail to your address if we have it, or
            otherwise to your email address. You and Glover’s Scorebooks will
            attempt to resolve any dispute through informal negotiation within
            sixty (60) days from the date the Notice of Dispute is sent. After
            sixty (60) days, you or Glover’s Scorebooks may commence
            arbitration.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Binding Arbitration</h1>
          <p className="mb-3">
            If you and Glover’s Scorebooks don’t resolve any dispute by informal
            negotiation, any other effort to resolve the dispute will be
            conducted exclusively by binding arbitration as described in this
            section. You are giving up the right to litigate (or participate in
            as a party or class member) all disputes in court before a judge or
            jury. The dispute shall be settled by binding arbitration in
            accordance with the commercial arbitration rules of the American
            Arbitration Association. Either party may seek any interim or
            preliminary injunctive relief from any court of competent
            jurisdiction, as necessary to protect the party’s rights or property
            pending the completion of arbitration. Any and all legal,
            accounting, and other costs, fees, and expenses incurred by the
            prevailing party shall be borne by the non-prevailing party.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Submissions and Privacy</h1>
          <p className="mb-3">
            In the event that you submit or post any ideas, creative
            suggestions, designs, photographs, information, advertisements, data
            or proposals, including ideas for new or improved products,
            services, features, technologies or promotions, you expressly agree
            that such submissions will automatically be treated as
            non-confidential and non-proprietary and will become the sole
            property of Glover’s Scorebooks without any compensation or credit
            to you whatsoever. Glover’s Scorebooks and its affiliates shall have
            no obligations with respect to such submissions or posts and may use
            the ideas contained in such submissions or posts for any purposes in
            any medium in perpetuity, including, but not limited to, developing,
            manufacturing, and marketing products and services using such ideas.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Promotions</h1>
          <p className="mb-3">
            Glover’s Scorebooks may, from time to time, include contests,
            promotions, sweepstakes, or other activities (“Promotions”) that
            require you to submit material or information concerning yourself.
            Please note that all Promotions may be governed by separate rules
            that may contain certain eligibility requirements, such as
            restrictions as to age and geographic location. You are responsible
            to read all Promotions rules to determine whether or not you are
            eligible to participate. If you enter any Promotion, you agree to
            abide by and to comply with all Promotions Rules.
          </p>
          <p className="mb-3">
            Additional terms and conditions may apply to purchases of goods or
            services on or through the Services, which terms and conditions are
            made a part of this Agreement by this reference.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Typographical Errors</h1>
          <p className="mb-3">
            In the event a product and/or service is listed at an incorrect
            price or with incorrect information due to typographical error, we
            shall have the right to refuse or cancel any orders placed for the
            product and/or service listed at the incorrect price. We shall have
            the right to refuse or cancel any such order whether or not the
            order has been confirmed and your credit card charged. If your
            credit card has already been charged for the purchase and your order
            is canceled, we shall immediately issue a credit to your credit card
            account or other payment account in the amount of the charge.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Miscellaneous</h1>
          <p className="mb-3">
            If for any reason a court of competent jurisdiction finds any
            provision or portion of these Terms & Conditions to be
            unenforceable, the remainder of these Terms & Conditions will
            continue in full force and effect. Any waiver of any provision of
            these Terms & Conditions will be effective only if in writing and
            signed by an authorized representative of Glover’s Scorebooks.
            Glover’s Scorebooks will be entitled to injunctive or other
            equitable relief (without the obligations of posting any bond or
            surety) in the event of any breach or anticipatory breach by you.
            Glover’s Scorebooks operates and controls the Glover’s Scorebooks
            Service from its offices in United States. The Service is not
            intended for distribution to or use by any person or entity in any
            jurisdiction or country where such distribution or use would be
            contrary to law or regulation. Accordingly, those persons who choose
            to access the Glover’s Scorebooks Service from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
            These Terms & Conditions (which include and incorporate the Glover’s
            Scorebooks Privacy Policy) contains the entire understanding, and
            supersedes all prior understandings, between you and Glover’s
            Scorebooks concerning its subject matter, and cannot be changed or
            modified by you. The section headings used in this Agreement are for
            convenience only and will not be given any legal import.
          </p>
        </div>
        <div className="mb-4 pt-3">
          <h1 className="text-2xl mb-2 font-medium">Disclaimer</h1>
          <p className="mb-3">
            Glover’s Scorebooks is not responsible for any content, code or any
            other imprecision.
          </p>
          <p className="mb-3">
            Glover’s Scorebooks does not provide warranties or guarantees.
          </p>
          <p className="mb-3">
            In no event shall Glover’s Scorebooks be liable for any special,
            direct, indirect, consequential, or incidental damages or any
            damages whatsoever, whether in an action of contract, negligence or
            other tort, arising out of or in connection with the use of the
            Service or the contents of the Service. The Company reserves the
            right to make additions, deletions, or modifications to the contents
            on the Service at any time without prior notice.
          </p>
          <p className="mb-6">
            The Glover’s Scorebooks Service and its contents are provided "as
            is" and "as available" without any warranty or representations of
            any kind, whether express or implied. Glover’s Scorebooks is a
            distributor and not a publisher of the content supplied by third
            parties; as such, Glover’s Scorebooks exercises no editorial control
            over such content and makes no warranty or representation as to the
            accuracy, reliability or currency of any information, content,
            service or merchandise provided through or accessible via the
            Glover’s page 10/10 Scorebooks Service. Without limiting the
            foregoing, Glover’s Scorebooks specifically disclaims all warranties
            and representations in any content transmitted on or in connection
            with the Glover’s Scorebooks Service or on sites that may appear as
            links on the Glover’s Scorebooks Service, or in the products
            provided as a part of, or otherwise in connection with, the Glover’s
            Scorebooks Service, including without limitation any warranties of
            merchantability, fitness for a particular purpose or
            non-infringement of third party rights. No oral advice or written
            information given by Glover’s Scorebooks or any of its affiliates,
            employees, officers, directors, agents, or the like will create a
            warranty. Price and availability information is subject to change
            without notice. Without limiting the foregoing, Glover’s Scorebooks
            does not warrant that the Glover’s Scorebooks Service will be
            uninterrupted, uncorrupted, timely, or error-free.
          </p>
          <div className="py-2">
          <a className="text-[#005DAB] text-xl font-semibold underline" href="">
            Click to download the Terms & Conditions
            </a>
          </div>
          <div className="pt-6">
            <h1 className="text-2xl font-semibold mb-2">Contact Us</h1>
            <p className="mb-3">Don't hesitate to contact us if you have any questions.</p>
            <ul>
              <li>● Via Email: tracysaul@fraziersports.com</li>
              <li>● Via Phone Number: 254-776-0580</li>
            </ul>
          </div>
        </div>
      </div>
      <footer class="bg-[#0A142F] text-white px-6 py-14 pb-12">
        <div class="sm:grid lg:grid-cols-3">
          <div class="flex flex-col space-y-6">
            <div>
              <img src={Logo} alt="" />
            </div>
            <div class="flex flex-row gap-2 items-center">
              <iconify-icon
                icon="gridicons:location"
                style={{ color: " white;" }}
                width="16"
                height="16"
              ></iconify-icon>
              <p class="text-sm font-light">
                345 Faulconer Drive, Suite 4 • Charlottesville, CA, 12345
              </p>
            </div>
            <div class="flex lg:flex-row flex-col lg:items-center items-start gap-8">
              <div>
                <div class="flex flex-row gap-2 lg:items-center items-start">
                  <iconify-icon
                    icon="material-symbols:call"
                    style={{ color: " white;" }}
                    width="16"
                    height="16"
                  ></iconify-icon>
                  <p class="text-sm font-light">254-776-3605</p>
                </div>
              </div>
              <div>
                <div class="flex flex-row gap-2 lg:items-center items-start">
                  <iconify-icon
                    icon="bi:printer-fill"
                    style={{ color: " white;" }}
                    width="16"
                    height="16"
                  ></iconify-icon>
                  <p class="text-sm font-light">254-776-9187</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex lg:justify-end lg:mt-0 mt-6">
            <div class="flex flex-col space-y-6">
              <p class="text-white text-xl font-normal">Quick Links</p>
             <p class="font-normal text-sm text-opacity-60 text-white">
                Order Research: <span class="font-semibold text-sm text-opacity-60 text-white underline">tracysaul@fraziersports.com</span>
              </p>
              <p class="font-normal text-sm text-white text-opacity-60">Dealer Inquires:&nbsp;
               <span class="font-semibold text-sm text-opacity-60 text-white underline">dealers@scorebooks.com</span>
              </p>
            </div>
          </div>

          <div class="flex lg:justify-end lg:mt-0 mt-6">
            <div class="flex flex-col space-y-6">
              <p class="text-white text-xl font-normal">Subscribe</p>
              <div class="bg-white w-full rounded-lg">
                <div class="flex items-center justify-between px-3 pe-0 h-12">
                  <p class="text-[#0A142F] me-6">Get product updates</p>
                  <div class="bg-[#005DAB] px-4 py-3 rounded-r-lg">
                    <iconify-icon
                      icon="majesticons:arrow-up-line"
                      style={{ color: " white;" }}
                      width="16"
                    ></iconify-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-6" />

        <div class="flex md:justify-between md:flex-row flex-col  items-center mt-6">
          <div class="flex flex-row gap-4">
            <iconify-icon
              icon="ri:facebook-fill"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
            <iconify-icon
              icon="mdi:linkedin"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
            <iconify-icon
              icon="ri:twitter-fill"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
          </div>
          <p class="text-sm font-thin md:mt-0 mt-6">
            © 2023 Glovers Scorebooks. All rights reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Termsandconditions;
