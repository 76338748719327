import React from "react";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Privacypolicy = () => {
  const navigate = useNavigate();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div class="block w-full  py-6 px-6  text-white border-b border-[#F26649]">
        <div class="flex items-center justify-between text-gray-900">
          <a href="#">
            <img src={Logo} alt="" />
          </a>
          <ul class="hidden items-center lg:gap-14 gap-4 md:flex">
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span class="text-with-dash line">Description</span>
              </a>
            </li>
            <li class="block text-base font-normal text-black leading-normal">
              <a class="items-center lg:flex hidden" href="#">
                <iconify-icon
                  icon="pepicons-pencil:line-y"
                  style={{ color: " #a7a7a8;" }}
                  width="16"
                ></iconify-icon>
              </a>
            </li>
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                <span class="text-with-dash line">Terms and Conditions</span>
              </a>
            </li>
            <li class="block text-base font-normal text-black leading-normal">
              <a class="lg:flex items-center  hidden" href="#">
                <iconify-icon
                  icon="pepicons-pencil:line-y"
                  style={{ color: "#a7a7a8;" }}
                  width="16"
                ></iconify-icon>
              </a>
            </li>
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                <span class="text-with-dash line">Privacy Policy</span>
              </a>
            </li>
          </ul>
          <div class="md:hidden" id="menu-icon">
            <iconify-icon
              icon="ri:menu-3-line"
              style={{ color: "black;" }}
              width="32"
              height="32"
              class="mt-2"
              id="menu-icon-btn"
            ></iconify-icon>
          </div>
        </div>

        <div class="md:hidden overflow-hidden" id="offcanvas-menu">
          <div class="close-icon" id="close-icon-btn">
            <iconify-icon
              icon="ri:close-line"
              style={{ color: "white;" }}
              width="30"
              height="30"
            ></iconify-icon>
          </div>
          <ul class="items-center lg:gap-14 gap-10 md:flex space-y-12 mt-12">
            <li class="block text-base font-normal hover:text-blue leading-normal cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                Description
              </a>
            </li>
            <li class="block text-base font-normal hover:text-blue leading-normal cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                Terms and Conditions
              </a>
            </li>
            <li class="block text-base font-normal hover:text-blue leading-normal cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                Pricay Policy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="md:py-8 md:px-8">
        <div className="mb-4">
          <h1 className="text-uppercase text-[#005DAB] font-semibold text-3xl mb-6">
            Thank you for using Glovers Scorebook!
          </h1>
          <p className="font-medium">
            At Glover's Scorebooks (“Scorebooks”, “Company”, “our”, or “we”), We
            respect your privacy and are committed to protecting it through our
            compliance with this privacy policy. This policy is designed to and
            describes:
          </p>
        </div>
        <div className="mb-4">
          <p className="mb-3">
            The types of personal information we may collect from you or that
            you may provide when you download, access, or use the Beach Runners
            mobile application (the “Mobile App”); and Our practices for
            collecting, using, maintaining, protecting, and disclosing that
            information.
          </p>
          <p className="mb-3">
            This policy applies to information we collect in this Mobile App, on
            our website and in email, text, and other electronic communications
            sent through or in connection with this Mobile App and website.
          </p>
          <p className="mb-3 font-medium">
            This policy DOES NOT apply to information that:
          </p>
          <p className="mb-2">
            We collect offline or on any other Company apps or websites,
            including websites you may access through this App.
          </p>
          <p className="mb-2">
            You provide to or is collected by any third party (see Third-Party
            Information Collection).
          </p>
          <p className="mb-2">
            Our websites and apps, and these other third parties may have their
            own privacy policies, which we encourage you to read before
            providing information on or through them.
          </p>
          <p className="mb-2">
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, do not download,
            register with, or use this Mobile App. By downloading, registering
            with, or using this Mobile App, you agree to this privacy policy.
            This policy may change from time to time (see Changes to Our Privacy
            Policy). Your continued use of this Mobile App after we revise this
            policy means you accept those changes, so please check the policy
            periodically for updates.
          </p>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Children Under the Age of 13{" "}
            </h1>
            <p className="mb-3">
              The App is not intended for children under 13 years of age, and we
              do not knowingly collect personal information from children under
              13. If we learn we have collected or received personal information
              from a child under 13 without verification of parental consent, we
              will delete that information. If you believe we might have any
              information from or about a child under 13, please contact us at
              [tracysaul@fraziersports.com or www.scorebookstore.com].
            </p>
            <p className="mb-3">
              Texas residents under 13 years of age may have additional rights
              regarding the collection and sale of their personal information.
              If you are a Texas resident, please see Your California Privacy
              Rights for more information.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Information We Collect and How We Collect It
            </h1>
            <p className="mb-3">
              We collect information from and about users of our Mobile App:
              Directly from you when you provide it to us; and Automatically
              when you use the Mobile App.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Information You Provide to Us 
            </h1>
            <p className="mb-3">
              When you download, register with, or use this Mobile App, we may
              ask that you provide personal information, such as your name,
              mailing address, email address, telephone number, and any other
              personally identifying information.
            </p>
            <p className="mb-3 font-medium">This information includes:</p>
            <p className="mb-2">
              Information that you provide by providing your information into
              the Mobile App.
            </p>
            <p className="mb-2">
              This includes information provided at the time of registering to
              use the Mobile App and when requesting our service. We may also
              ask you for information when you report a problem with the Mobile
              App. when you report a problem with the Mobile App. Records and
              copies of your correspondence (including email addresses and phone
              numbers), if you contact us.
            </p>
            <p className="mb-2">
              Details of transactions you carry out through the App and of the
              fulfilment of your orders. You may be required to provide
              financial information before placing an order through the App.
            </p>
            <p className="mb-2">Your search queries on the Mobile App.</p>
            <p className="mb-2">
              You may also provide information for publication or display
              (“Posted”) on public areas of the app or websites you access
              through the App (collectively, “User Contributions”). Your User
              Contributions are Posted and transmitted to others at your own
              risk. Additionally, we cannot control the actions of third parties
              with whom you may choose to share your User Contributions.
            </p>
            <p className="mb-2">
              Therefore, we cannot and do not guarantee that your User
              Contributions will not be viewed by unauthorized persons.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Automatic Information Collection and Tracking  
            </h1>
            <p className="mb-3 font-medium">
              When you download, access, and use the Mobile App, it may use
              technology to automatically collect:
            </p>
            <p className="mb-2">
              Usage Details. When you access and use the Mobile App, we may
              automatically collect certain details of your access to and use of
              the Mobile App, including traffic data, location data, logs, and
              other communication data and the resources that you access and use
              on or through the Mobile App.{" "}
            </p>
            <p className="mb-2">
              Device Information. We may collect information about your mobile
              device and internet connection, including the device's unique
              device identifier, IP address, Operating System, browser type,
              mobile network information, and the device’s Telephone number.
            </p>
            <p className="mb-2">
              Stored Information and Files. The Mobile App also may access
              metadata and other information associated with other files stored
              on your device. This may include, for example, photographs, audio
              and video clips, personal contacts, and address book information.
            </p>
            <p className="mb-2">
              Location Information. This App collects real-time information
              about the location of your device. When you request our service
              through the Mobile App, we will determine your location in order
              to provide our services. If you do not want us to collect this
              information do not download the App or delete it from your device.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Information Collection and Tracking Technologies  
            </h1>
            <p className="mb-3 font-medium">
              The technologies we use for automatic information collection may
              include:
            </p>
            <p className="mb-2">
              Cookies (or mobile cookies). A cookie is a small file placed on
              your smartphone. It may be possible to refuse to accept mobile
              cookies by activating the appropriate setting on your smartphone.
              However, if you select this setting, you may be unable to access
              certain parts of our Mobile App and/or receive our services.
            </p>
            <p className="mb-2">
              Web Beacons. Pages of the Mobile App and our emails may contain
              small electronic files known as web beacons (also referred to as
              clear gifs, pixel tags, and single-pixel gifs) that permit the
              Company, for example, to count users who have visited those pages
              or opened an email and for other related app statistics (for
              example, recording the popularity of certain app content and
              verifying system and server integrity).
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Third-Party Information Collection
            </h1>
            <p className="mb-3 font-medium">
              When you use the Mobile App or its content, certain third parties
              may use automatic information collection technologies to collect
              information about you or your device. These third parties may
              include:
            </p>
            <p className="mb-1">Advertisers, ad networks and ad servers.</p>
            <p className="mb-1">Analytics companies.</p>
            <p className="mb-1">Your mobile device manufacturer.</p>
            <p className="mb-1">Your mobile service provider.</p>
            <p className="mb-1">
              Do Not Track Signals. The Mobile App is not configured to read or
              respond to “do not track” signals or other mechanisms. Other
              parties may collect personally identifiable information about your
              activities over time and across different websites when a user
              uses the Mobile App.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              How We Use Your Information
            </h1>
            <p className="mb-2">
              Fulfill our obligations to you as a User and Customer;
            </p>
            <p className="mb-2">
              Locate you when you request through the Mobile App the use of our
              services;
            </p>
            <p className="mb-2">
              Provide you with notices about your account and to improve
              customer service (your information helps us to more effectively
              respond to your customer service requests and support needs);
            </p>
            <p className="mb-2">
              Carry out our obligations and enforce our rights arising from any
              contracts entered into between you and us, including for billing
              and collection;
            </p>
            <p className="mb-2">
              Notify you when Mobile App updates are available, and of changes
              to any products or services we offer or provide though the Mobile
              App;
            </p>
            <p className="mb-2">
              Allow you to participate in interactive features on the Mobile App
              and to personalize your experience (your information helps us to
              better respond to your individual needs, which helps us improve
              the Mobile App);
            </p>
            <p className="mb-2">
              Enable communications to you regarding our services such as email
              and text; Work with marketing partners to provide you with
              products or services that may be of interest to you;
            </p>
            <p className="mb-2">
              Fulfill any other purpose for which you provide it and in any
              other war we may describe when you provide the information; and
            </p>
            <p className="mb-2">Fulfill any other purpose with your consent.</p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">
              Disclosure of Your Information
            </h1>
            <p className="mb-3 font-medium">
              We may disclose information we collect about you or thar you
              provide us directly, as described in this policy:
            </p>
            <p className="mb-2">To our subsidiaries and affiliates;</p>
            <p className="mb-2">
              To contractors, service providers, and other third parties we use
              to support our business;
            </p>
            <p className="mb-2">
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Beach Runner LLC's assets,
              whether as a going concern or as part of bankruptcy, liquidation,
              or similar proceeding, in which personal information held by
              Scorebooks about our Mobile App users is among the assets
              transferred;
            </p>
            <p className="mb-2">
              To fulfil the purpose for which you provide it. For example, if
              you give us a phone number through the Mobile App, so we can call
              you to perform our services;
            </p>
            <p className="mb-2">
              For any other purpose disclosed by us when you provide the
              information; and With your consent.
            </p>
            <p className="mb-3 pt-3 font-medium">
              We may also disclosure your personal information:
            </p>
            <p className="mb-2">
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request;
            </p>
            <p className="mb-2">
              To enforce our rights arising from any contracts entered into
              between you and us, including the Mobile App End User Licensing
              Agreement (“EULA”), terms of sale [LINK TO APP'S TERMS OF SALE OF
              GOODS AND SERVICES], and for billing and collection.{" "}
            </p>
            <p className="mb-2">
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of Glover’s Scorebooks, our
              customers, or others. This includes exchanging information with
              other companies and organizations for the purposes of fraud
              protection and credit risk reduction.
            </p>
            <p className="mb-2">
              Accessing and Correcting Your Personal Information
            </p>
            <p className="mb-2">
              You can review and change your personal information by logging
              into the Mobile App and visiting your account profile page.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">Data Security</h1>
            <p className="mb-3">
              We have implemented measures designed to secure your personal
              information from accidental loss and from unauthorized access,
              use, alteration, and disclosure. The safety and security of your
              information also depends on you. Where we have given you (or where
              you have chosen) a password for access to certain parts of our
              Mobile App, you are responsible for keeping this password
              confidential. We ask you not to share your password with anyone.
            </p>
            <p className="mb-3">
              Unfortunately, the transmission of information via the internet
              and mobile platforms is not completely secure. Although we do our
              best to protect your personal information, we cannot guarantee the
              security of your personal information transmitted through our
              Mobile App. Any transmission of personal information is at your
              own risk. We are not responsible for circumvention of any privacy
              settings or security measures we provide.
            </p>
          </div>
          <div className="pt-3">
            <h1 className="text-2xl font-medium mb-3">Changes to Our Privacy Policy</h1>
            <p className="mb-3">
            We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and notify you an in-App alert the first time you use the App after we make the change. 
            </p>
            <p className="mb-3">
            The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this privacy policy to check for any changes. 
            </p>
          </div>
          <div className="py-2">
          <a className="text-[#005DAB] text-xl font-semibold underline" href="">
            Click to download the Privacy Policy
            </a>
          </div>
          <div className="pt-6">
            <h1 className="text-2xl font-semibold mb-2">Contact Information</h1>
            <p>
            To ask questions or comment about this privacy policy and our privacy practices, contact us at: 
            </p>
            <a className="underline text-[#005DAB]" href="">
            tracysaul@fraziersports.com
            </a>
          </div>
        </div>
        <div className="mb-4 pt-6">
          <h1 className="text-2xl mb-2 font-medium underline">Glovers Scorebook </h1>
          <h1 className="text-uppercase text-2xl mb-2 font-medium">
            MOBILE APPLICATION END USER LICENSE AGREEMENT THIS MOBILE
            APPLICATION END USER LICENSE AGREEMENT
          </h1>
          <p>
            (“Agreement”) is a binding agreement between you (“End User” or
            “you”) and Glover’s Scorebooks (“Company”). This Agreement governs
            your use of the Glover’s Scorebooks mobile application (including
            all related documentation, the “Application”). The Application is
            licensed, not sold, to you.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="text-uppercase text-2xl mb-2 font-medium">
            BY DOWNLOADING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE
            READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF
            LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS
            AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU
            DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION AND DELETE
            IT FROM YOUR MOBILE DEVICE.
          </h1>
          <p className="mb-3">
            1. License Grant. Subject to the terms of this Agreement, Company
            grants you a limited, non-exclusive, and nontransferable license to:
            (a) download, install, and use the Application for your personal,
            non-commercial use on a single mobile device owned or otherwise
            controlled by you (“Mobile Device”) strictly in accordance with the
            Application's documentation; and (b) access, stream, download, or
            use on such Mobile Device the Content and Services (as defined in
            Section 5) made available in or otherwise accessible through the
            Application, strictly in accordance with this Agreement and the
            Terms of Use applicable to such Content and Services as set forth in
            Section 5.
          </p>
          <p className="mb-3">
            2. License Restrictions. You shall not: (a) Copy the Application,
            except as expressly permitted by this license; (b) Modify,
            translate, adapt, or otherwise create derivative works or
            improvements, whether or not patentable, of the Application; (c)
            Reverse engineer, disassemble, decompile, decode, or otherwise
            attempt to derive or gain access to the source code of the
            Application or any part thereof; (d) Remove, delete, alter, or
            obscure any trademarks or any copyright, trademark, patent, or other
            intellectual property or proprietary rights notices from the
            Application, including any copy thereof; (e) Rent, lease, lend,
            sell, sublicense, assign, distribute, publish, transfer, or
            otherwise make available the Application, or any features or
            functionality of the Application, to any third party for any reason,
            including by making the Application available on a network where it
            is capable of being accessed by more than one device at any time;
            (f) Remove, disable, circumvent, or otherwise create or implement
            any workaround to any copy protection, rights management, or
            security features in or protecting the Application;
          </p>
          <p className="mb-3">
            3. Reservation of Rights. You acknowledge and agree that the
            Application is provided under license, and not sold, to you. You do
            not acquire any ownership interest in the Application under this
            Agreement, or any other rights thereto other than to use the
            Application in accordance with the license granted, and subject to
            all terms, conditions, and restrictions, under this Agreement.
            Company and its licensors and service providers reserve and shall
            retain their entire right, title, and interest in and to the
            Application, including all copyrights, trademarks, and other
            intellectual property rights therein or relating thereto, except as
            expressly granted to you in this Agreement.
          </p>
          <p className="mb-3">
            4. Collection and Use of Your Information. You acknowledge that when
            you download, install, or use the Application, Company may use
            automatic means (including, for example, cookies and web beacons) to
            collect information about your Mobile Device and about your use of
            the Application. You also may be required to provide certain
            information about yourself as a condition to downloading, installing
            or using the Application or certain of its features or
            functionality. All information Company collects through or in
            connection with this Application is subject to Company’s Privacy
            Policy, located at https:// www.gloversscorebooks.com/Privacy. By
            downloading, installing, using, and providing information to or
            through this Application, you consent to all actions taken by us
            with respect to your information in compliance with the Privacy
            Policy.
          </p>
          <p className="mb-3">
            5. Content and Services. The Application may provide you with access
            to Company's website located at https:// www.gloversscorebooks.com/
            (the “Website”) and products and services accessible thereon, and
            certain features, functionality, and content accessible on or
            through the Application may be hosted on the Website (collectively,
            “Content and Services”). Your access to and use of such Content and
            Services are governed by the Website's Terms of Use and Privacy
            Policy located at https:// www.gloversscorebooks.com/Privacy, which
            are incorporated herein by this reference. Your access to and use of
            such Content and Services may require you to acknowledge your
            acceptance of such Terms of Use and Privacy Policy and/or to
            register with the Website, and your failure to do so may restrict
            you from accessing or using certain of the Application's features
            and functionality. Any violation of such Terms of Use will also be
            deemed a violation of this Agreement.
          </p>
          <p className="mb-3">
            6. Geographic Restrictions. The Content and Services are based in
            the state of Alabama in the United States and provided for access
            and use only by persons located in the United States. You
            acknowledge that you may not be able to access all or some of the
            Content and Services outside of the United States and that access
            thereto may not be legal by certain persons or in certain countries.
            If you access the Content and Services from outside the United
            States, you are responsible for compliance with local laws.
          </p>
          <p className="mb-3">
            7. Updates. Company may from time to time in its sole discretion
            develop and provide Application updates, which may include upgrades,
            bug fixes, patches, other error corrections, and/or new features
            (collectively, including related documentation, “Updates”). Updates
            may also modify or delete in their entirety certain features and
            functionality. You agree that Company has no obligation to provide
            any Updates or to continue to provide or enable any particular
            features or functionality. Based on your Mobile Device settings,
            when your Mobile Device is connected to the internet either: (a) the
            Application will automatically download and install all available
            Updates; or (b) you may receive notice of or be prompted to download
            and install available Updates. You shall promptly download and
            install all Updates and acknowledge and agree that the Application
            or portions thereof may not properly operate should you fail to do
            so. You further agree that all Updates will be deemed part of the
            Application and be subject to all terms and conditions of this
            Agreement.
          </p>
          <p className="mb-3">
            8. Third-Party Materials. The Application may display, include, or
            make available third-party content (including data, information,
            applications, and other products, services, and/or materials) or
            provide links to third-party websites or services, including through
            third-party advertising (“Third-Party Materials”). You acknowledge
            and agree that Company is not responsible for Third-Party Materials,
            including their accuracy, completeness, timeliness, validity,
            copyright compliance, legality, decency, quality, or any other
            aspect thereof. Company does not assume and will not have any
            liability or responsibility to you or any other person or entity for
            any Third-Party Materials. Third-Party Materials and links thereto
            are provided solely as a convenience to you, and you access and use
            them entirely at your own risk and subject to such third parties'
            terms and conditions.
          </p>
          <p className="mb-3">
            9. Term and Termination. (a) The term of Agreement commences when
            you download the Application and will continue in effect until
            terminated by you or Company as set forth in this Section 9. (b) You
            may terminate this Agreement by deleting the Application and all
            copies thereof from your Mobile Device. (c) Company may terminate
            this Agreement at any time without notice if it ceases to support
            the Application, which Company may do in its sole discretion. In
            addition, this Agreement will terminate immediately and
            automatically without any notice if you violate any of the terms and
            conditions of this Agreement, or if your continued use of the
            Application violates the law in the jurisdiction in which you are
            located. (d) Upon termination: (i) all rights granted to you under
            this Agreement will also terminate; and (ii) you must cease all use
            of the Application and delete all copies of the Application from
            your Mobile Device and account. (e) Termination will not limit any
            of Company's rights or remedies at law or in equity.
          </p>
          <p className="mb-3">
            10. Disclaimer of Warranties. TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW, THE APPLICATION IS PROVIDED TO END USER “AS IS” AND
            WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
            MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN
            BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
            LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES,
            WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO
            THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
            DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
            LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR
            UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
            APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
            RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
            APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION,
            MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR
            THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. 
          </p>
          <p className="mb-3">
            11. Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY
            OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY
            LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE
            THE APPLICATION OR THE CONTENT AND SERVICES FOR: (a) PERSONAL
            INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR
            SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION,
            COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL,
            INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES. (b)
            DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT
            ACTUALLY PAID BY YOU FOR THE APPLICATION. THE FOREGOING LIMITATIONS
            WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT,
            TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER
            SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p className="mb-3">
            12. Indemnification. You agree to indemnify, defend, and hold
            harmless Company and its officers, directors, employees, agents,
            affiliates, successors, and assigns from and against any and all
            losses, damages, liabilities, deficiencies, claims, actions,
            judgments, settlements, interest, awards, penalties, fines, costs,
            or expenses of whatever kind, including attorneys' fees, arising
            from or relating to your use or misuse of the Application or your
            breach of this Agreement, including but not limited to the content
            you submit or make available through this Application.
          </p>
          <p className="mb-3">
            13. Export Regulation. The Application may be subject to US export
            control laws, including the Export Control Reform Act and its
            associated regulations. You shall not, directly or indirectly,
            export, re-export, or release the Application to, or make the
            Application accessible from, any jurisdiction or country to which
            export, re-export, or release is prohibited by law, rule, or
            regulation. You shall comply with all applicable federal laws,
            regulations, and rules, and complete all required undertakings
            (including obtaining any necessary export license or other
            governmental approval), prior to exporting, re-exporting, releasing,
            or otherwise making the Application available outside the US.
          </p>
          <p className="mb-3">
            14. US Government Rights. The Application is commercial computer
            software, as such term is defined in 48 C.F.R. § 2.101. Accordingly,
            if you are an agency of the US Government or any contractor
            therefor, you receive only those rights with respect to the
            Application as are granted to all other end users under license, in
            accordance with (a) 48 C.F.R. § 227.7201 through 48 C.F.R. §
            227.7204, with respect to the Department of Defense and their
            contractors, or (b) 48 C.F.R. § 12.212, with respect to all other US
            Government licensees and their contractors.
          </p>
          <p className="mb-3">
            15. Severability. If any provision of this Agreement is illegal or
            unenforceable under applicable law, the remainder of the provision
            will be amended to achieve as closely as possible the effect of the
            original term and all other provisions of this Agreement will
            continue in full force and effect.
          </p>
          <p className="mb-3">
            16. Governing Law. This Agreement is governed by and construed in
            accordance with the internal laws of the State of Alabama without
            giving effect to any choice or conflict of law provision or rule.
            Any legal suit, action, or proceeding arising out of or related to
            this Agreement or the Application shall be instituted exclusively in
            the federal courts of the United States or the courts of the State
            of Alabama in each case located in Birmingham and Jefferson. You
            waive any and all objections to the exercise of jurisdiction over
            you by such courts and to venue in such courts.
          </p>
          <p className="mb-3">
            17. Litigation. Any legal suit, action, or proceeding arising out of
            or related to this Agreement or the licenses granted hereunder will
            be instituted exclusively in the State of Alabama in each case
            located in the city of Birmingham and County of Jefferson County,
            and each Party irrevocably submits to the exclusive jurisdiction of
            such courts in any such suit, action, or proceeding.
          </p>
          <p className="mb-3">
            18. Limitation of Time to File Claims. ANY CAUSE OF ACTION OR CLAIM
            YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE
            APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF
            ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS
            PERMANENTLY BARRED.
          </p>
          <p className="mb-3">
            19. Entire Agreement. This Agreement and Company’s Privacy Policy
            constitute the entire agreement between you and Company with respect
            to the Application and supersede all prior or contemporaneous
            understandings and agreements, whether written or oral, with respect
            to the Application.
          </p>
          <p className="mb-6">
            20. Waiver. No failure to exercise, and no delay in exercising, on
            the part of either party, any right or any power hereunder shall
            operate as a waiver thereof, nor shall any single or partial
            exercise of any right or power hereunder preclude further exercise
            of that or any other right hereunder. In the event of a conflict
            between this Agreement and any applicable purchase or other terms,
            the terms of this Agreement shall govern. Click to Download the
            Mobile End User License Agreement
          </p>
          <div className="py-2">
          <a className="text-[#005DAB] text-xl font-semibold underline" href="">
            Click to download the Privacy Policy
            </a>
          </div>
          <div className="pt-6">
            <h1 className="text-2xl font-semibold mb-2">Contact Information</h1>
            <p>
              To ask questions or comment about our Mobile Application End User
              License Agreement, contact us at:{" "}
            </p>
            <a className="underline text-[#005DAB]" href="">
            tracysaul@fraziersports.com
            </a>
          </div>
        </div>
      </div>
      <footer class="bg-[#0A142F] text-white px-6 py-14 pb-12">
        <div class="sm:grid lg:grid-cols-3">
          <div class="flex flex-col space-y-6">
            <div>
              <img src={Logo} alt="" />
            </div>
            <div class="flex flex-row gap-2 items-center">
              <iconify-icon
                icon="gridicons:location"
                style={{ color: " white;" }}
                width="16"
                height="16"
              ></iconify-icon>
              <p class="text-sm font-light">
                345 Faulconer Drive, Suite 4 • Charlottesville, CA, 12345
              </p>
            </div>
            <div class="flex lg:flex-row flex-col lg:items-center items-start gap-8">
              <div>
                <div class="flex flex-row gap-2 lg:items-center items-start">
                  <iconify-icon
                    icon="material-symbols:call"
                    style={{ color: " white;" }}
                    width="16"
                    height="16"
                  ></iconify-icon>
                  <p class="text-sm font-light">254-776-3605</p>
                </div>
              </div>
              <div>
                <div class="flex flex-row gap-2 lg:items-center items-start">
                  <iconify-icon
                    icon="bi:printer-fill"
                    style={{ color: " white;" }}
                    width="16"
                    height="16"
                  ></iconify-icon>
                  <p class="text-sm font-light">254-776-9187</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex lg:justify-end lg:mt-0 mt-6">
            <div class="flex flex-col space-y-6">
              <p class="text-white text-xl font-normal">Quick Links</p>
             <p class="font-normal text-sm text-opacity-60 text-white">
                Order Research: <span class="font-semibold text-sm text-opacity-60 text-white underline">tracysaul@fraziersports.com</span>
              </p>
              <p class="font-normal text-sm text-white text-opacity-60">Dealer Inquires:&nbsp;
               <span class="font-semibold text-sm text-opacity-60 text-white underline">dealers@scorebooks.com</span>
              </p>
            </div>
          </div>

          <div class="flex lg:justify-end lg:mt-0 mt-6">
            <div class="flex flex-col space-y-6">
              <p class="text-white text-xl font-normal">Subscribe</p>
              <div class="bg-white w-full rounded-lg">
                <div class="flex items-center justify-between px-3 pe-0 h-12">
                  <p class="text-[#0A142F] me-6">Get product updates</p>
                  <div class="bg-[#005DAB] px-4 py-3 rounded-r-lg">
                    <iconify-icon
                      icon="majesticons:arrow-up-line"
                      style={{ color: " white;" }}
                      width="16"
                    ></iconify-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-6" />

        <div class="flex md:justify-between md:flex-row flex-col  items-center mt-6">
          <div class="flex flex-row gap-4">
            <iconify-icon
              icon="ri:facebook-fill"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
            <iconify-icon
              icon="mdi:linkedin"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
            <iconify-icon
              icon="ri:twitter-fill"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
          </div>
          <p class="text-sm font-thin md:mt-0 mt-6">
            © 2023 Glovers Scorebooks. All rights reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Privacypolicy;
