import React from "react";
import Logo from "../../assets/images/logo.png";
import Scror1 from "../../assets/images/img1.png";
import Scror2 from "../../assets/images/img2.png";
import Baseball from "../../assets/images/baseball.png";
import Volyball from "../../assets/images/volyball.png";
import Football from "../../assets/images/football.png";
import Baset from "../../assets/images/baset.png";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div class="block w-full py-6 px-6 text-white border-b border-[#F26649]">
        <div class="flex items-center justify-between text-gray-900">
          <a href="#">
            <img src={Logo} alt="" />
          </a>
          <ul class="hidden items-center lg:gap-14 gap-4 md:flex">
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <div
                class="flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span class="text-with-dash line">Description</span>
              </div>
            </li>
            <li class="block text-base font-normal text-black leading-normal">
              <a class="items-center lg:flex hidden" href="#">
                <iconify-icon
                  icon="pepicons-pencil:line-y"
                  style={{ color: " #a7a7a8;" }}
                  width="16"
                ></iconify-icon>
              </a>
            </li>
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                <span class="text-with-dash line">Terms and Conditions</span>
              </a>
            </li>
            <li class="block text-base font-normal text-black leading-normal">
              <a class="lg:flex items-center  hidden" href="#">
                <iconify-icon
                  icon="pepicons-pencil:line-y"
                  style={{ color: "#a7a7a8;" }}
                  width="16"
                ></iconify-icon>
              </a>
            </li>
            <li class="block text-base text-black font-normal leading-normal hover:text-[#005DAB] hover:font-semibold active:text-[#005DAB] active:font-semibold focus:text-[#005DAB] focus:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                <span class="text-with-dash line">Privacy Policy</span>
              </a>
            </li>
          </ul>
          <div class="md:hidden" id="menu-icon">
            <iconify-icon
              icon="ri:menu-3-line"
              style={{ color: "black;" }}
              width="32"
              height="32"
              class="mt-2"
              id="menu-icon-btn"
            ></iconify-icon>
          </div>
        </div>

        <div class="md:hidden overflow-hidden" id="offcanvas-menu">
          <div class="close-icon" id="close-icon-btn">
            <iconify-icon
              icon="ri:close-line"
              style={{ color: "white;" }}
              width="30"
              height="30"
            ></iconify-icon>
          </div>
          <ul class="items-center lg:gap-14 gap-10 md:flex space-y-12 mt-12">
            <li class="block text-base font-normal hover:text-blue leading-normal active:text-[#005DAB] active:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                Description
              </a>
            </li>
            <li class="block text-base font-normal hover:text-blue leading-normal active:text-[#005DAB] active:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/termsandconditions");
                }}
              >
                Terms and Conditions
              </a>
            </li>
            <li class="block text-base font-normal hover:text-blue leading-normal active:text-[#005DAB] active:font-semibold cursor-pointer">
              <a
                class="flex items-center"
                onClick={() => {
                  navigate("/privacypolicy");
                }}
              >
                Pricay Policy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="md:py-6 md:px-6">
        <div class="Banner  md:px-14 px-4">
          <div class="ellip">
            <div class="w-full">
              <h1 class="md:text-8xl text-6xl text-black font-extrabold">
                Score <br /> With Us
              </h1>
              <p class="text-black font-semibold mt-8">
                The trusted name in sports scorebooks and accessories for 40
                years.
              </p>
              <div class="bg-[#f2efef] bg-opacity-75  w-full  px-4 py-2 mt-12 rounded-lg">
                <div class="flex md:justify-between  md:flex-row flex-col md:items-center">
                  <p class="text-base text-[#757575] flex row gap-2">
                    <iconify-icon
                      icon="carbon:search"
                      style={{ color: " #757575" }}
                      width="16"
                      height="16"
                      class="mt-1"
                    ></iconify-icon>
                    Search
                  </p>
                  <button
                    type="button"
                    class="px-6 py-1 border-2 border-[#005DAB] rounded-lg text-[#005DAB] text-base font-semibold md:mt-0 mt-2 md:flex hidden"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex lg:flex-row flex-col  gap-4">
          <div class="lg:basis-1/2 basis-full md:px-6 px-4">
            <h1 class="text-black text-4xl font-semibold mt-8">
              Welcome to the official <br /> Glovers's scorebooks store
            </h1>
            <p class="text-black mt-8 lg:leading-loose text-xl leading-loose">
              Since 1975 Glover's Scorebooks has been the most trusted name in
              tracking baseball and softball scores with their official
              scorebooks. Now, with the new Glover's Scorebooks App, coaches,
              players, and fans can connect to the game in a whole new way. The
              Glover's Scorebooks app is an powerful tool for coaches, providing
              them with comprehensive team management and scoring capabilities.
              With this app, coaches can efficiently handle tasks like managing
              team members, game schedules, and easily message players or fans.
              The Glover's Scorebooks app also allows coaches to keep track of
              game statistics, including pitch count, pitch type, pitch speed,
              batting roster, and many more essential statistics. With the
              Glover's Scorebooks app, players can stay up-to-date on their
              team's game schedule, locations, and receive real-time
              notifications from their coaches. Fans can follow their favorite
              teams to view their game schedules and even live stream games
              directly from their mobile device!   
            </p>
            <div class="mt-8">
              <a
                href="#"
                class="text-black flex items-center gap-4 underline font-semibold"
              >
                Read More{" "}
                <iconify-icon
                  icon="mdi:chevron-right-box"
                  width="24"
                  height="24"
                ></iconify-icon>
              </a>
            </div>
          </div>
          <div class="lg:basis-1/2 basis-full  lg:mt-6 mt-4">
            <img src={Scror1} alt="" />
          </div>
        </div>
      </div>

      <div class="bg-[#f4f4f4] mt-8 pb-8">
        <div class="flex lg:flex-row flex-col">
          <div class="lg:basis-1/2 basis-full mt-14">
            <img src={Scror2} alt="" />
          </div>
          <div class="lg:basis-1/2 basis-full mt-14">
            <div class="flex flex-col  lg:px-24 px-6 ">
              <h1 class="text-4xl font-bold text-black">
                Score Book Categeory
              </h1>
              <p class="text-black mt-8 leading-loose text-xl text-start">
                Tom Glover's Scorebooks have types of category for each games to
                notify the scores as you like and it helps you to score your
                games, and maintain stats and tendencies for players and teams
                from season to season. Our products aid both players and coaches
                alike by providing easy to follow stats, and allows you to keep
                a history of each player for scholarships, tournament play and
                team placement.
              </p>
            </div>
            <div class="mt-8 flex justify-start lg:px-24 px-6 ">
              <a href="#" class="text-black flex items-center gap-4 underline font-semibold">
                Read More{" "}
                <iconify-icon
                  icon="mdi:chevron-right-box"
                  width="24"
                  height="24"
                ></iconify-icon>
              </a>
            </div>
          </div>
        </div>

        <div class="carousel">
          <div class="sm:grid lg:grid-cols-4 grid-cols-2   gap-4 px-6 mt-20">
            <div class="relative">
              <img src={Baseball} alt="Image" class="w-full h-full" />
              <button class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-8 py-2 bg-white text-black  text-base font-medium">
                BASEBALL
              </button>
            </div>

            <div class="relative">
              <img src={Volyball} alt="Image" class="w-full h-full" />
              <button class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-8 py-2 bg-white text-black  text-base font-medium">
                VOLLEYBALL
              </button>
            </div>

            <div class="relative">
              <img src={Football} alt="Image" class="w-full h-full" />
              <button class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-8 py-2 bg-white text-black  text-base font-medium">
                FOOTBALL
              </button>
            </div>

            <div class="relative">
              <img src={Baset} alt="Image" class="w-full h-full" />
              <button class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-8 py-2 bg-white text-black  text-base font-medium">
                BASKETBALL
              </button>
            </div>
          </div>
          <div class="carousel-indicators">
            <div class="carousel-indicator"></div>
            <div class="carousel-indicator"></div>
            <div class="carousel-indicator"></div>
          </div>
        </div>
      </div>

      <footer class="bg-[#0A142F] text-white px-6 py-14 mt-8 pb-12">
        <div class="sm:grid lg:grid-cols-3">
          <div class="flex flex-col space-y-6">
            <div>
              <img src={Logo} alt="" />
            </div>
            <div class="flex flex-row gap-2 items-center">
              <iconify-icon
                icon="gridicons:location"
                style={{ color: " white;" }}
                width="16"
                height="16"
              ></iconify-icon>
              <p class="text-sm font-light">
                345 Faulconer Drive, Suite 4 • Charlottesville, CA, 12345
              </p>
            </div>
            <div class="flex lg:flex-row flex-col lg:items-center items-start gap-8">
              <div>
                <div class="flex flex-row gap-2 lg:items-center items-start">
                  <iconify-icon
                    icon="material-symbols:call"
                    style={{ color: " white;" }}
                    width="16"
                    height="16"
                  ></iconify-icon>
                  <p class="text-sm font-light">254-776-3605</p>
                </div>
              </div>
              <div>
                <div class="flex flex-row gap-2 lg:items-center items-start">
                  <iconify-icon
                    icon="bi:printer-fill"
                    style={{ color: " white;" }}
                    width="16"
                    height="16"
                  ></iconify-icon>
                  <p class="text-sm font-light">254-776-9187</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex lg:justify-end lg:mt-0 mt-6">
            <div class="flex flex-col space-y-6">
              <p class="text-white text-xl font-normal">Quick Links</p>
              <p class="font-normal text-sm text-opacity-60 text-white">
                Order Research: <span class="font-semibold text-sm text-opacity-60 text-white underline">tracysaul@fraziersports.com</span>
              </p>
              <p class="font-normal text-sm text-white text-opacity-60">Dealer Inquires:&nbsp;
               <span class="font-semibold text-sm text-opacity-60 text-white underline">dealers@scorebooks.com</span>
              </p>
            </div>
          </div>

          <div class="flex lg:justify-end lg:mt-0 mt-6">
            <div class="flex flex-col space-y-6">
              <p class="text-white text-xl font-normal">Subscribe</p>
              <div class="bg-white w-full rounded-lg">
                <div class="flex items-center justify-between px-3 pe-0 h-12">
                  <p class="text-[#0A142F] me-6">Get product updates</p>
                  <div class="bg-[#005DAB] px-4 py-3 rounded-r-lg">
                    <iconify-icon
                      icon="majesticons:arrow-up-line"
                      style={{ color: " white;" }}
                      width="16"
                    ></iconify-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-6" />

        <div class="flex md:justify-between md:flex-row flex-col  items-center mt-6">
          <div class="flex flex-row gap-4">
            <iconify-icon
              icon="ri:facebook-fill"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
            <iconify-icon
              icon="mdi:linkedin"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
            <iconify-icon
              icon="ri:twitter-fill"
              style={{ color: " white;" }}
              width="16"
              height="16"
            ></iconify-icon>
          </div>
          <p class="text-sm font-thin md:mt-0 mt-6">
            © 2023 Glovers Scorebooks. All rights reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Home;
