import React from 'react'
import { Route, Routes, Outlet } from "react-router-dom";
import Home from '../pages/Home';
import Termsandconditions from '../pages/Home/Termsandconditions';
import Privacypolicy from '../pages/Home/Privacypolicy';
const Router = () => {
    const Layout = () => {
        return (
            <div className=' w-full h-full flex flex-col'>
                <Outlet />
            </div>
        )
    }
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path={'/'} element={<Home />} />
                <Route path={'/termsandconditions'} element={<Termsandconditions />} />
                <Route path={'/privacypolicy'} element={<Privacypolicy />} />
            </Route>
        </Routes>
    )
}

export default Router