import Router from "./router";
import './style.scss';

function App() {
  return (
    <Router />
  );
}

export default App;
